.choose-avatar-main {
  .avatar-images{
    display: flex;
    gap: 42px 24px;
    flex-wrap: wrap;
    padding: 10px 42px;
  }
  @media screen and (max-width: 767px) {
    .avatar-images{
      display: flex !important;
      gap: 42px 68px !important; 
      flex-wrap: wrap !important;
      padding: 10px 60px !important;
    }
  }
  strong{
    font-size: 20px;
    font-weight: 600;
  }
  .filter-bar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    .custom-button {
      width: 44px;
      height: 40px;
      padding: 7px;
      border-radius: 4px;
      border: 1px solid var(--line, #DEE1E6);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10) !important;
     img , svg {
        color: #2181FA;
        width: 24px;
        height: 24px;
      }
    }

  }
// padding: 0px 20px;
  .flex-box,
  .flex-box-three,
  .flex-box-two,
  .flex-box-four {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-box {
    width: 100% !important;
  }

  /* .card {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      border: none;
      border-radius: 8px;
      max-width: 1148px;
      margin: 40px auto;
      width: 95%;
      padding: 20px 40px;
    } */

  .heading {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .btn-one {
    background-color: #2181FA;
    border: none;
    color: white !important;
    padding: 6px 20px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    height: 40px;
  }

  .input {
    border: none !important;
    outline: none;
    width: 100%;
  }

  .filter-outer {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #DEE1E6;
    display: flex;
    gap: 18px;
    margin: 0px 10px;
    padding: 3px 14px;
    width: 72%;
    height: 40px;
  }

  .dropdown-toggle {
    background-color: #fff;
    color: #2D2F31;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    padding: 6px 12px;
    height: 100%;
    box-shadow: none !important;
    border: 1px solid #DEE1E6 !important;
    height: 40px;
  }

  .dropdown-toggle:hover,
  .dropdown-toggle:focus {
    background-color: #fff;
    color: #2D2F31;
    border: 1px solid #DEE1E6;
  }

  .dropdown-menu {
    display: none;
  }

  .dropdown-toggle:focus+.dropdown-menu {
    display: block;
  }

  .flex-box-four {
    display: flex;
    gap: 10px 15px;
    flex-wrap: wrap;
    margin: 30px 0px;
    justify-content: space-between;
  }

  .avatar-img {
    margin-bottom: 10px;
    margin: 2px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .avatar-img:focus {
    border: 2px solid #22C55E;
    border-radius: 50%;
    margin: 0px;
  }

    .Avatars {
      position: relative;
      width: fit-content;

      .avatar-box{
        .circle-icon{
          top: 0px !important;
          left: -1px !important;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
  
      span {
        color: #2D2F31;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        padding: 12px;
      }
    }

  .avatar-img+.fa-circle-check {
    display: none;
  }

  .avatar-img:focus+.fa-circle-check {
    display: inline-block;
    position: absolute;
    right: 0px;
    color: #22C55E;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .choose-avatar-main{

  .flex-box,
  .flex-box-three {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .btn-one {
    width: 100% !important;
  }

  .pagination {
    flex-wrap: wrap;
    width: 100%;
  }

  .page-link,
  .page.link:hover {
    margin-bottom: 5px;
  }

  .flex-box-four {
    gap: 10px;
  }

  .flex-box-two {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 0px !important;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .filter-outer {
    width: 100%;
    margin: auto 0px;
  }
}
}


.pagination-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  padding: 20px 30px;
  margin: 0 0 20px;

  position: fixed;
  bottom: 0;
  right: 4%;
  width: 100%;
  margin-bottom: 0;
  background: #fff;
  @media (max-width: 767px) {
    gap: 8px;
    justify-content: center;
    padding: 20px 15px;
}
  span{
    color: #2D2F31;
    font-size: 14px;
     @media (max-width: 767px) {
      font-size: 12px;
  }
  }
  .form-select{
    width: auto;
    display: inline-flex;
    border: none;
    box-shadow: none;
    color: #2D2F31;
    font-size: 14px;
    padding: 0 34px 0 0;
    height: auto;
    margin: 0 0 0 0;
    margin-top: 0 !important;
     @media (max-width: 767px) {
      font-size: 12px;
  }
    &:focus{
      box-shadow: none;
    }
  }
}

.profile-img-modal{
  .modal-dialog{
    .modal-content{
      .modal-body{
        padding: 0px;
        .modal-footer{
          button{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 12px 18px;
            border: 1px solid #2181FA;
            background-color: #2181FA;
            color: #FFFFFF;
            border-radius: 5px;
            height: 36px;
            // width: 90px !important;
            max-width: 90px;
            width: 100% !important;
            &.cancel-btn{
              background-color: transparent;
              color: #000;
            }
          }
        }
      }
    }
  }
}