@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css");
$main: #2181fa;
$main-bg: linear-gradient(90deg, #e95567 0%, #f36735 100%);
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

h1,
h2,
h3,
h4,
h5,
h6,
b,
small,
body {
  font-family: Poppins !important;
}

p,
span,
a,
.nav-link,
.nav-item,
.navbar-nav,
input {
  font-family: Poppins !important;
}

input,
textarea,
button,
.btn {
  outline: 2px solid transparent !important;
  box-shadow: none !important;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $main;
}

html,
body {
  transition: all ease-in-out 0.4s;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

.lg-container {
  // padding: 0px 100px !important;
  max-width: 1400px;
  margin: auto;
}

body {
  font-family: Poppins;
  overflow-x: hidden;
}

.animatedup {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.animatedleft {
  animation: left-right 1s ease-in-out infinite alternate-reverse both;
}

@keyframes left-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.Toastify__toast {
  margin-bottom: 0px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #e5ffea !important;
  color: #000 !important;
  border-radius: 0px;

  .Toastify__close-button--colored {
    width: 14px !important;
    height: 14px !important;
    color: #2f2f2f !important;
    margin: 9px 35px !important;
  }

  .Toastify__toast-icon.Toastify--animate-icon {
    color: #36ba3c !important;
  }

  .Toastify__toast-body {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    // font-family: $poppins !important;
    padding: 0px 35px !important;
    margin: 0px !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #fdf4e7 !important;
  color: #000 !important;
  border-radius: 0px;

  .Toastify__close-button--colored {
    width: 14px !important;
    height: 14px !important;
    color: #2f2f2f !important;
    margin: 9px 35px !important;
  }

  .Toastify__toast-icon.Toastify--animate-icon {
    color: #ff9900 !important;
  }

  .Toastify__toast-body {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    // font-family: $poppins !important;
    padding: 0px 35px !important;
    margin: 0px !important;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #fae0e0 !important;
  color: #000 !important;
  border-radius: 0px;

  .Toastify__close-button--colored {
    width: 14px !important;
    height: 14px !important;
    color: #2f2f2f !important;
    margin: 9px 35px !important;
  }

  .Toastify__toast-icon.Toastify--animate-icon {
    color: #ff0000 !important;
  }

  .Toastify__toast-body {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    // font-family: $poppins !important;
    padding: 0px 35px !important;
    margin: 0px !important;
  }
}

.container-fluid {
  overflow-x: hidden;
  padding: 0px !important;
}

.container-full {
  padding: 0px 70px;
  width: 100%;
}

.note {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
}

.auth-header {
  background: white;
  min-height: 80px;
  z-index: 99;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;

  .header-row {
    display: flex;
    justify-content: space-between;
  }

  .logout {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    i {
      font-size: 16px;
    }
  }

  .logo {
    height: 40px;
  }
}

.auth-footer {
  width: 100%;
  height: 60px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-body {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .nav-link {
      color: black;
      font-size: 14px;
    }
  }
}

.auth-body {
  padding: 15px;
  padding-bottom: 100px;
  width: 100%;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../src/images/loginbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  width: 100%;
  // height: 100vh;

  .auth-form {
    width: 562px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #ffffff;
    /* Shadow (SM) */
    padding: 75px 70px;
    box-shadow: $card-shadow;
    border-radius: 8px;

    .form-check-label {
      font-size: 12px;
    }

    .diff-btn {
      padding: 10px 0px;
      border-radius: 0px;
      font-size: 14px;
      letter-spacing: 0.4px;
      border-bottom: 1px solid #cccccc;
    }

    .mail-img {
      height: 128px;
      margin-bottom: 20px;
    }

    .fav {
      height: 100px;
      margin-bottom: 15px;
    }

    .signup-btns {
      width: 420px;
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-outline {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        width: 100%;
        margin-bottom: 16px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 7px;

        img {
          width: 15px;
          margin-right: 10px;
        }
      }
    }

    h2 {
      font-weight: 600;
      font-size: 33px;
      margin-bottom: 25px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: -0.03em;
      color: #202223;
    }

    h6 {
      font-weight: 400;
      font-size: 22px;
      letter-spacing: -0.02em;
      margin-bottom: 45px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #3d3d3d;

      a {
        margin-left: 5px;
        color: $main;
        text-decoration: none;
      }
    }

    small {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #3d3d3d;

      a {
        margin-left: 5px;
        color: $main;
        text-decoration: none;
      }
    }

    .field-row {
      text-align: left;
      width: 422px;

      .org-name-field {
        position: relative;

        .lable2 {
          height: 48px;
          position: absolute;
          right: 0px;
          color: #202223;
          font-size: 14px;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;

          span {
            padding: 0px 15px;
          }
        }
      }

      .form-label {
        // font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        color: #202223;
      }

      input {
        padding-left: 15px;
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 7px;
        height: 48px;
        font-size: 14px;
      }

      select {
        border: 1px solid #cccccc;
        border-radius: 7px;
        height: 50px;
      }

      .btn {
        width: 100%;
        height: 48px;
        border: none;
        background: #2181fa;
        border-radius: 7px;
        font-size: 14px;
        margin-top: 25px !important;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  h5 {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 30px;
  }

  .devider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px 30px;

    .xyz {
      width: 495px;
      height: 1px;
      background-color: #d9d9d9;
    }

    .or {
      margin-top: -15px;
      padding: 0px 10px;
      background-color: white;
      font-size: 18px;
      color: #3d3d3d;
    }
  }

  .network-url {
    display: flex;
    width: 422px;
    margin-bottom: 25px;
    justify-content: space-between;

    input {
      width: calc(100% - 70px);
      height: 50px;
      border: 1px solid #cccccc;
      border-radius: 7px;
    }

    .btn {
      width: 50px;
      height: 50px;
      background: #f1f1f1;
      border-radius: 6px;

      i {
        color: #0000008a;
      }
    }
  }
}

.go2392553372 {
  padding-left: 0px !important;
  max-width: 562px;
}

.go2150698616:before {
  left: 27% !important;
}

.go2392553372 li::before {
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  bottom: -100px !important;
}

.go433304200:before {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  background-color: #33c3f0 !important;
}

.go3842760039:before {
  content: "o" !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  background-color: #33c3f0 !important;
}

.image-uploader {
  height: 110px;
  width: 110px;
  background: #f0f0f0;
  border-radius: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  line-height: 14px;
  flex-direction: column;
  overflow: hidden;
  object-fit: cover;

  img {
    height: 110px;
    object-fit: cover;
    width: 110px;
  }

  small {
    color: #8c9196;
    font-size: 12px !important;
    font-weight: 600;
  }

  i {
    margin-top: 20px;
    color: #8c9196;
    margin-bottom: 5px;
    font-size: 18px;
  }
}

.StepperContainer-0-2-1 {
  width: 562px;
  padding: 20px 0px !important;

  button.active {
    background: $main !important;
  }

  button.completed {
    background: $main !important;
  }
}

.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100px;
  }
}

.organizations {
  .des {
    font-size: 9px;
    margin-left: 3px;
    color: gray;
  }

  .capsol {
    display: flex;
    height: 50px;
    border: 1px solid #cccccc;
    padding: 5px;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 10px;

    .card-body {
      .card-title {
        margin-bottom: 0px;
        color: black;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        text-transform: capitalize;
      }

      .card-text {
        font-size: 11px;
        color: black;
        text-transform: capitalize;
      }
    }

    img {
      height: 40px;
      border-radius: 4px;
      width: 40px;
      margin-right: 8px;
      object-fit: cover;
    }
  }

  a {
    text-decoration: none !important;
  }

  .capsol:hover {
    box-shadow: $card-shadow;
    border: 1px solid #cccccc;

    .card-title {
      color: $main;
    }
  }
}

.org-name-field {
  .form-control {
    background-image: none !important;
  }
}

.modal-error-message {
  color: red;
  font-size: 14px;
  letter-spacing: 0;
}

.error-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  img {
    height: 350px;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
  }

  h2 {
    font-weight: 700;
    font-size: 180px;
    color: #dc3545;
  }

  h5 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }
}

.loading-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  img {
    height: 250px;
  }

  h1 {
    font-size: 38px;
    font-weight: 600;
  }

  h5 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
  }
}

.loading-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;

  .cube-loading-outer {

    //custom mixins
    @mixin transform($transform) {
      -webkit-transform: $transform;
      -moz-transform: $transform;
      transform: $transform;
    }

    @mixin animation($animation) {
      -webkit-animation: $animation;
      -moz-animation: $animation;
      animation: $animation;
    }

    @mixin keyframes($name, $name1) {
      @-webkit-keyframes #{$name} {
        @content;
      }

      @-moz-keyframes #{$name} {
        @content;
      }

      @-ms-keyframes #{$name} {
        @content;
      }

      @keyframes #{$name} {
        @content;
      }
    }

    @mixin opacity($opacity) {
      -webkit-opacity: $opacity;
      -moz-opacity: $opacity;
      opacity: $opacity;
    }

    @mixin border-radius($radius) {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      border-radius: $radius;
    }

    position: relative;
    height: 500px;
    //variables
    $size: 50px;

    //cube only
    .cube-folding {
      width: $size;
      height: $size;
      display: inline-block;
      @include transform(rotate(45deg));
      font-size: 0;

      span {
        position: relative;
        width: calc($size/2);
        height: calc($size/2);
        @include transform(scale(1.1));
        display: inline-block;

        &::before {
          content: "";
          background-color: $main;
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: calc($size/2);
          height: calc($size/2);
          -moz-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          @include animation(folding 2.5s infinite linear both);
        }
      }

      .leaf2 {
        @include transform(rotateZ(90deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
          background-color: darken($main, 5%);
        }
      }

      .leaf3 {
        @include transform(rotateZ(270deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
          background-color: darken($main, 5%);
        }
      }

      .leaf4 {
        @include transform(rotateZ(180deg) scale(1.1));

        &::before {
          -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
          background-color: darken($main, 10%);
        }
      }
    }

    //animation
    @include keyframes(folding, folding animation) {

      0%,
      10% {
        @include transform(perspective(140px) rotateX(-180deg));
        @include opacity(0);
      }

      25%,
      75% {
        @include transform(perspective(140px) rotateX(0deg));
        @include opacity(1);
      }

      90%,
      100% {
        @include transform(perspective(140px) rotateY(180deg));
        @include opacity(0);
      }
    }

    //with loading text and shadow
    .cube-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -$size;
      margin-left: -$size;
      width: $size * 2;
      height: $size * 2;
      text-align: center;

      //shadow
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
        width: $size * 1.8;
        height: 6px;
        z-index: 1;
        background-color: rgba(black, 0.1);
        -webkit-filter: blur(2px);
        filter: blur(2px);
        @include border-radius(100%);
        @include animation(shadow 0.5s ease infinite alternate);
      }

      .loading {
        // font-size: 12px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1em;
        display: block;
        color: $main;
        position: relative;
        top: calc($size/2);
        z-index: 2;
        @include animation(text 0.5s ease infinite alternate);
      }

      @include keyframes(text, text animation) {
        100% {
          top: (calc($size/2)) + 10;
        }
      }

      @include keyframes(shadow, shadow animation) {
        100% {
          bottom: -18px;
          width: $size * 2;
        }
      }
    }
  }

  .confirmation-popup {
    width: 100%;
  }
}

// accounts screens

.accounts-body {
  padding: 90px 20px;
  min-height: 100vh;
  // max-height: 100vh;
  position: relative !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // background-image: url("../src/images/loginbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  // margin-bottom: 150px;
  // padding-bottom: 200px !important;
  &.main-accounts-body{
    // margin-bottom: 77px;
    // padding-bottom: 77px !important;
    // padding-bottom: 170px !important;
  }

  @media only screen and (max-height: 840px) {
    height: auto;
    min-height: calc(100vh - 70px);
    padding: 90px 20px 30px;
  }

  // .main-accounts-body{
  //   position: relative;
  // }
  .accounts-logo {
    // height: 36px;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dee1e6;
    padding: 14px;
    width: 100%;
    background: #ffffff;
    z-index: 9999;

    img,
    svg {
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }

    a {
      color: #2181fa;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  // .inner-body{
  //   padding: 150px 0 100px 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  .create-account-card {
    width: 100%;
    max-width: 700px;
    position: relative;
    min-height: 300px;
    padding: 64px;
    border-radius: 10px;
    border: 1px solid #dee1e6;
    box-shadow: 0px 6px 14px 0px #0000001a;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // margin-bottom: 200px;

    h1 {
      color: var(--On-Surface-Text-Colour, #202223);
      text-align: center;
      font-family: Poppins;
      margin-bottom: 40px;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -1.28px;
      line-height: 40px;
    }

    .email-signup-btn {
      border: 1px solid var(--line, #dee1e6);
      background: #fff;
      display: inline-flex;
      padding: 12px 14px;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 50px;

      i {
        color: #a29e9e;
        margin-right: 15px;
      }

      span {
        color: #a29e9e;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .devider {
      padding: 20px 0px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        margin-top: -12px;
        padding: 0px 20px;
        background: white;
        color: var(--text-2, #a29e9e);
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.26px;
      }

      .linee {
        width: 100%;
        height: 1px;
        background: #dee1e6;
      }
    }

    .other-options {
      width: 100%;
      margin-bottom: 20px;

      .option-btn {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 8px;
        border: 1px solid var(--line, #dee1e6);
        background: #fff;

        strong {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #2d2f31;
        }

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }

    .already-have-account {
      padding: 20px 0px;
      width: 100%;
      color: #9e9fa2;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-align: center;

      a {
        color: var(--Peimary, #2181fa) !important;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        i {
          font-size: 12px;
          margin-left: 3px;
        }
      }
    }

    .terms-privacy {
      max-width: 330px;
      margin: auto;
      padding: 20px 0px;
      color: #9e9fa2;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      a {
        color: #2181fa !important;
        display: inline-block;
      }
    }

    .help-center {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;

      a {
        color: var(--text-2, #a29e9e) !important;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        padding: 0px 10px;
        font-weight: 400;
        line-height: 18px;
      }

      span {
        font-size: 12px;
        color: #f2f3f4;
      }
    }

    .btn-primary {
      height: 48px;
      width: 100%;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: capitalize;
    }

    .form-card {
      width: 100%;

      .progress {
        width: 100%;
        height: 8px;
        border-radius: 5px;
        position: relative;
        top: -15px;
        background-color: #d9d9d9;
      }

      .form-label {
        position: relative;
        top: -12px;
        font-size: 14px;
        font-weight: 400;
        color: #a29e9e;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .form-control {
        height: 50px;
        width: 100%;
        border-radius: 7px;
        color: var(--On-Surface-Border, #8c9196);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border: 1px solid var(--line, #dee1e6);
        background: var(--Surface-White, #fff);
        margin-bottom: 20px;
        border-radius: 7px !important;
      }

      .form-control:invalid:focus {
        border-color: #dee1e6;
      }

      .form-control:valid {
        border-color: #dee1e6;
      }

      .forgot-pass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .form-check-input {
          border-color: #6d7175;
        }

        .form-check-input:valid:checked {
          background-color: #2181fa;
          color: #ffffff;
          border: none;
        }

        .form-check-label {
          color: var(--On-Surface-Text-Subdued, #6d7175);

          /* Paragraph/P1/Regular */
          font-family: Poppins;
          font-size: 14px;
          font-style: nor mal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
        }

        a {
          color: var(--Main-Color-Main-Color, #2181fa) !important;
          text-align: right;
          cursor: pointer;
          /* Paragraph/P1/Regular */
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 128.571% */
        }
      }

      .create-label {
        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #9e9fa2;
          padding: 0 0 4px 0;
        }
      }

      .input-group {
        position: relative;

        i {
          position: absolute;
          z-index: 99;
          top: 17px;
          right: 24px;
          color: #a29e9e;
          cursor: pointer;
        }

        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #9e9fa2;
          // padding: 0 0 4px 0;
          padding: 0 0 16px 0;
        }
      }

      .form-code {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px 20px;

        .form-control {
          width: 58px;
          height: 68px !important;
          color: #202223 !important;
          text-align: center;
          font-family: Poppins;
          font-size: 32px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 48px;
          /* 150% */
          letter-spacing: -1.28px;
        }
      }

      .user-card {
        min-height: 66px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--line, #dee1e6);
        background: #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 12px 25px;
        align-items: center;

        .profile {
          display: flex;
          align-items: center;

          .user {
            height: 42px;
            width: 42px;
            border-radius: 100px;
            margin-right: 10px;
          }

          span {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .edit {
          cursor: pointer;
          background-color: transparent;
          border: 0px;

          i {
            color: #a29e9e;
          }
        }
      }

      .wrong-pass {
        input {
          border: 1px solid #ef4444 !important;
        }

        i {
          color: #ef4444 !important;
        }

        input::placeholder {
          color: #ef4444 !important;
        }
      }

      .successPass {
        // color: #4cc05c;
        background-color: #4cc05c;
      }

      .dangerPass {
        // color: #fd5c5c;
        background-color: #fd5c5c;
      }
    }

    .wrong-pass-card {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 110px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #fae0e0;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding: 30px 75px;

      span {
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
        color: #fd5c5c;
      }
    }

    .right-pass-card {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 110px;
      border-radius: 7px;
      background: #e5ffea;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      span {
        color: var(--Primary-text, #2d2f31);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
        color: #36ba3c;
      }
    }
  }
}

.organization-body {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  // background-image: url("../src/images/loginbackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;

  .organizations {
    margin: 60px 0 0 0;

    @media (max-width: 768px) {
      margin: 30px 0 0 0;
    }

    .looking-organization {
      p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #9e9fa2;

        @media (max-width: 768px) {
          font-size: 12px;
          text-align: center;
        }
      }

      span {
        font-weight: 600;
        color: #2181fa;
        padding-left: 10px;
      }
    }
  }

  .organization-logo {
    height: 36px;
    top: 10px;
    left: 16px;
    position: absolute;
  }

  .create-organization-card {
    width: 100%;
    max-width: 540px;
    position: relative;
    background: #fff;
    align-items: center;
    // padding-top: 160px;
    // padding: 50px 40px 48px 50px !important;
    // border-radius: 10px !important;
    // background: #fff !important;
    // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1) !important;

    h1 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 50px;
      letter-spacing: -1.28px;
    }

    h6 {
      color: var(--Primary-text, #2d2f31);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 40px;
    }

    .input-group {
      input {
        display: flex;
        height: 50px;
        padding: 16px 12px;
        align-items: center;
        border-radius: 7px;
        border: 1px solid var(--line, #dee1e6);
        background: var(--Surface-White, #fff);
      }
    }

    .upload-image-box {
      border: none !important;

      label.upload-label {
        border: 1px dashed #2181fa !important;
        border-radius: 10px !important;
        padding: 58px 60px;
        width: 100%;
      }

      .icon-wrapper {
        border: 1px solid #dee1e6;
        border-radius: 4px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 3px 0px #0000001a;
        margin-inline: auto;
        margin-bottom: 12px;

        img,
        svg {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }

      input {
        visibility: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      .text-wrapper {
        text-align: center;
        font-size: 0;

        strong {
          color: #8c9196;
          font-size: 14px;
          display: block;
          line-height: 18px;
          font-weight: 400;
        }

        span {
          color: #8c9196;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    .input-group-text-box {
      border-radius: 7px;
      border: 1px solid var(--line, #dee1e6);
      background: #fff;

      input {
        padding: 15px 24px;
        border: none !important;
        border-radius: 7px 0px 0px 7px !important;
        font-weight: 600;
      }

      .form-control.is-valid,
      .was-validated .form-control:valid {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important;
      }

      .input-group-text {
        background: #f5f5f5;
        color: var(--text-2, #a29e9e);
        text-align: right;
        padding: 15px 24px;
        /* Paragraph/P1/Semi Bold */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 128.571% */
        border: none !important;
      }
    }

    .action-btns {
      padding-top: 30px;

      .btn-light {
        display: flex;
        width: 100%;
        padding: 14.77px 38px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        background: #f5f5f5;
        color: var(--text-2, #a29e9e);
        text-align: center;

        /* Paragraph/P1/Semi Bold */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }

      .btn-primary {
        display: flex;
        width: 100%;
        padding: 14.77px 38px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        background: var(--Main-Color-Main-Color, #2181fa);
        color: var(--Yoda-Admin-Template-Blacks-B-0, #fff);
        text-align: center;

        /* Paragraph/P1/Semi Bold */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 128.571% */
      }
    }

    .alert-danger {
      background-color: transparent;
      border: none;
      color: #ef4444;
      margin-top: 10px !important;
      padding: 0px 0 0 20px;
    }
  }

  .tab-screen {
    position: absolute;
    bottom: 0;
    width: 920px;
    height: 20%;
    border-radius: 20px 20px 0px 0px;
    border: 13px solid var(--line, #dee1e6);
    border-bottom: none;
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 25px 0px;

    .form {
      width: 550px;

      input {
        display: flex;
        height: 50px;
        padding: 16px 12px 16px 20px;
        border-radius: 7px;
        border: 1px solid var(--line, #dee1e6);
        background: var(--Surface-White, #fff);
        align-items: center;
        width: 100%;
        color: black !important;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 90% */
      }

      input::placeholder {
        color: black !important;
      }
    }

    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 100px;

      .red {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        background: #fb5c51;
        border-radius: 100px;
        margin: 0px 7px;
      }

      .yellow {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        background: #fbc025;
        border-radius: 100px;
        margin: 0px 7px;
      }

      .green {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        background: #30c654;
        border-radius: 100px;
        margin: 0px 7px;
      }
    }
  }
}

.SetData-Btn {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;

  button {
    border: none;
    background-color: #f9f9f9;
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    margin-left: 7px;
    border-radius: 2px;
    text-transform: none;
  }
}

.accounts-body .create-account-card .other-options .option-btn:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .accounts-body .accounts-logo {
    // display: none;
  }

  .accounts-body .create-account-card {
    padding: 40px 20px;
  }

  .accounts-body .create-account-card h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .accounts-body .create-account-card .other-options .option-btn {
    height: 50px;
    border-radius: 5px;

    cursor: pointer;

    img {
      height: 30px;
    }
  }

  .accounts-body .create-account-card .terms-privacy,
  .accounts-body .create-account-card .already-have-account {
    padding: 10px 0px;
    font-size: 12px;

    a {
      font-size: 12px;
    }
  }

  .accounts-body .create-account-card .help-center a {
    font-size: 12px;
  }

  .accounts-body .create-account-card .form-card .form-code .form-control {
    width: 40px;
    height: 60px !important;
    font-size: 28px;
  }

  .accounts-body .create-account-card {
    width: 100%;
    border-radius: 6px;
  }

  .accounts-body .create-account-card .form-card .user-card {
    padding: 10px 15px;
  }

  .accounts-body .create-account-card .form-card .user-card .profile span {
    font-size: 16px;
  }

  .accounts-body .create-account-card .form-card .forgot-pass .form-check-label,
  .accounts-body .create-account-card .form-card .forgot-pass a {
    font-size: 12px;
  }

  .accounts-body .create-account-card .wrong-pass-card {
    height: 90px;
    padding: 30px 20px;

    span {
      font-size: 12px;
    }

    i {
      font-size: 20px;
    }
  }

  .accounts-body .create-account-card .right-pass-card {
    height: 80px;
    border-radius: 6px;

    span {
      font-size: 12px;
    }

    i {
      font-size: 20px;
    }
  }

  .organization-body .create-organization-card {
    width: 100%;
    padding: 100px 0;
    // margin-top: -60px;
  }

  .organization-body .organization-logo {
    display: none;
  }

  .organization-body .create-organization-card h1 {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .organization-body .create-organization-card h6 {
    margin-top: -15px;
  }

  .organization-body .create-organization-card .action-btns .btn-primary,
  .organization-body .create-organization-card .action-btns .btn-light {
    padding: 12px 38px;
  }

  .organization-body .create-organization-card .input-group-text-box input,
  .organization-body .create-organization-card .input-group-text-box .input-group-text {
    padding: 15px;
    font-size: 12px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 991px) {
  .organization-body {
    padding: 80px 20px 0px;

    // justify-content: space-between;
    .create-organization-card {
      h6 {
        margin-top: -10px;
      }
    }

    .tab-screen {
      width: 100%;
      position: static !important;
      margin: 40px 20px 0px;
    }
  }

  .organization-body .tab-screen .form {
    width: 100%;
  }

  .organization-body .tab-screen .dots {
    display: none;
  }

  .organization-body .tab-screen {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .organization-body {
    padding: 0px 20px 0px;
    // margin-top: -100px;
  }
}

@media only screen and (max-width: 576px) {
  .organization-body {
    padding: 0 20px 0;

    // margin-top: -215px;
    .create-organization-card {
      padding: 100px 0;

      h1 {
        font-size: 24px;
        margin-bottom: 30px;
      }

      h6 {
        font-size: 10px;
        margin-bottom: 20px;
        margin-top: -20px;
      }

      .action-btns {
        margin-top: 10px;
      }
    }

    .organizations {
      margin: 20px 0 0 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .organization-body {
    padding: 20px 20px;
    // margin-top: -100px;
    min-height: calc(100vh - 55px);
    height: 100% !important;
  }
}

// pagination styling start here..............
.div-23 {
  align-content: flex-start;
  flex-wrap: wrap;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: -82px 1px 37px 0;
}

@media (max-width: 991px) {
  .div-23 {
    max-width: 100%;
    margin: 40px 10px 0 0;
  }
}

.span-11 {
  display: flex;
  gap: 8px;
}

.div-24 {
  color: var(--Primary-text, #2d2f31);
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-24 {
    white-space: initial;
  }
}

.span-12 {
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  padding: 10px 9px;
}

.div-25 {
  color: var(--Primary-text, #2d2f31);
  text-align: center;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-25 {
    white-space: initial;
  }
}

.img-15 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.div-26 {
  color: var(--Peimary, #2181fa);
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 600 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-26 {
    white-space: initial;
  }
}

.div-27 {
  display: flex;
  gap: 6px;
}

@media (max-width: 991px) {
  .div-27 {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.span-13 {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  gap: 4px;
  padding: 10px 12px;
}

.img-16 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}

.div-28 {
  color: var(--colors-black-1-primary-text, #313131);
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-28 {
    white-space: initial;
  }
}

.span-14 {
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  gap: 4px;
  padding: 10px 12px;
}

.img-17 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  max-width: 100%;
  margin: auto 0;
}

.div-29 {
  color: var(--colors-black-1-primary-text, #313131);
  align-self: stretch;
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-29 {
    white-space: initial;
  }
}

.span-15 {
  color: var(--colors-black-1-primary-text, #313131);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 18px;
  font: 400 14px/143% Poppins, sans-serif;
  border: none;
}

@media (max-width: 991px) {
  .span-15 {
    white-space: initial;
  }
}

@media (max-width: 991px) {
  .span-16 {
    white-space: initial;
  }
}

@media (max-width: 991px) {
  .span-17 {
    white-space: initial;
  }
}

.div-30 {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  aspect-ratio: 1;
  flex-direction: column;
  width: 40px;
  height: 40px;
  padding: 0 12px;
}

.img-18 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 991px) {
  .span-19 {
    white-space: initial;
  }
}

.span-20 {
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  gap: 4px;
  padding: 10px 12px;
}

.div-31 {
  color: var(--colors-black-1-primary-text, #313131);
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-31 {
    white-space: initial;
  }
}

.img-19 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.span-21 {
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background-color: #fff;
  display: flex;
  gap: 4px;
  padding: 10px 12px;
}

.div-32 {
  color: var(--colors-black-1-primary-text, #313131);
  flex-grow: 1;
  white-space: nowrap;
  font: 400 14px/143% Poppins, sans-serif;
}

@media (max-width: 991px) {
  .div-32 {
    white-space: initial;
  }
}

.img-20 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

@media only screen and (min-width: 600px) {
  .div-27 {
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) {
  .div-27 {
    justify-content: flex-start;
  }
}

// organzation scss start here

.organzation-outer {
  background-color: var(--White, #fff);
  padding: 96px 0 0 0;
  //  background-image: url("../src/images/loginbackground.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-position: fixed;
  width: 100%;
  // height: 100vh;
  height: calc(100vh - 100px);
  overflow: auto;


  @media (max-width: 991px) {
    // padding-right: 20px;
    padding: 30px 0px 0px 20px;
  }

  @media (max-width: 767px) {
    // padding-right: 13px;
    padding: 0px 0px 0px 8px;
  }

  @media (max-width: 576px) {
    // padding-right: 13px;
    padding: 20px 0px 0px 0px;
  }

  .img {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 36px;
    overflow: hidden;
    max-width: 100%;
  }

  .inner {
    background-color: var(--White, #fff);
    align-self: center;
    display: flex;
    width: 100%;
    // max-width: 1148px;
    flex-direction: column;
    // position: relative;

    @media (max-width: 767px) {
      margin: 31px 0 31px;
      margin-left: 0;
    }

    @media (max-width: 576px) {
      margin: 0px 0 31px;
    }
  }

  @media (max-width: 991px) {
    .div-2 {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  .main-org {
    justify-content: space-between;
    align-items: flex-start;
    // border-bottom: 1px solid var(--line, #dee1e6);
    background-color: var(--White, #fff);
    display: flex;
    gap: 20px;
    align-items: center;
  }

  @media (max-width: 991px) {
    .main-org {
      padding: 15px 20px 0;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .main-org {
      padding: 10px 14px 0;
      align-items: center;
    }
  }

  .org-head {
    h2 {
      color: var(--Primary-text, #2d2f31);
      letter-spacing: -0.4px;
      font: 600 32px/40px Poppins, sans-serif;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 0 0;
      }

      @media (max-width: 576px) {
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 0 0;
      }
    }
  }

  .img-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }

  .org-filter {
    display: flex;
    margin-top: 35px;
    flex-direction: column;
    margin-bottom: 80px;
  }

  @media (max-width: 991px) {
    .org-filter {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  .filter {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px;
  }

  @media (max-width: 991px) {
    .filter {
      max-width: 100%;
      margin-right: 5px;
      flex-wrap: wrap;
    }
  }

  .filter-outer {
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--White, #fff);
    align-self: center;
    display: flex;
    gap: 14px;
    margin: auto 0;
    padding: 0 14px;
    width: 100%;
    height: 40px;
  }

  @media (max-width: 991px) {
    .filter-outer {
      max-width: 100%;
      flex-wrap: wrap;
      padding-right: 20px;
    }
  }

  .input {
    color: var(--text-2, #a29e9e);
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    border: none;
    font: 400 14px Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .input {
      white-space: initial;
    }
  }

  .all {
    align-items: center;
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid var(--line, #dee1e6);
    border-bottom: 1px solid var(--line, #dee1e6);
    border-left: 1px solid var(--line, #dee1e6);
    border-right: 1px solid var(--line, #dee1e6);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--White, #fff);
    align-self: center;
    display: flex;
    gap: 14px;
    margin: auto 0;
    padding: 8px 10px 8px 20px;
    height: 44px;
  }

  .all-content {
    color: var(--Primary-text, #2d2f31);
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
  }

  .img-4 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }

  .div-9 {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 1px;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  .img-6 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 20px;
    fill: var(--Primary-text, #2d2f31);
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .img-7 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .div-10 {
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--White, #fff);
    display: flex;
    aspect-ratio: 1;
    flex-direction: column;
    width: 44px;
    height: 44px;
    padding: 0 10px;

    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
    }
  }

  .img-8 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
  }

  .org-feild {
    justify-content: space-between;
    align-self: stretch;
    border-bottom: 1px solid var(--line, #dee1e6);
    background-color: var(--White, #fff);
    display: flex;
    margin-top: 38px;
    width: 100%;
    align-items: center;
    height: 60px;
  }

  @media (max-width: 991px) {
    .org-feild {
      margin-right: 5px;
      flex-wrap: wrap;
      // padding-left: 20px;
      padding-left: 0px;
    }
  }

  @media (max-width: 576px) {
    .org-feild {
      flex-wrap: nowrap;
      padding-left: 10px;
    }
  }

  .feild {
    justify-content: space-between;
    display: flex;
    gap: 16px;
    padding: 10px 16px 10px 10px;

    @media (max-width: 576px) {
      gap: 5px;
      padding: 5px;
    }
  }

  .feild-content {
    color: var(--Primary-text, #2d2f31);
    flex-grow: 1;
    white-space: nowrap;
    font: 500 14px/24px Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .feild-content {
      white-space: initial;
    }
  }

  @media (max-width: 576px) {
    .feild-content {
      white-space: initial;
      font: 500 12px/24px Poppins, sans-serif;
    }
  }

  .img-9 {
    aspect-ratio: 0.92;
    object-fit: contain;
    object-position: center;
    width: 12px;
    fill: var(--line, #dee1e6);
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .domain-outer {
    justify-content: flex-end;
    align-self: center;
    display: flex;
    padding-right: 6px;
    gap: 16px;
    margin: auto 0;
  }

  .do-content {
    color: var(--On-Surface-Text-Colour, #202223);
    font: 500 14px/24px Poppins, sans-serif;
  }

  @media (max-width: 576px) {
    .do-content {
      font: 500 12px/24px Poppins, sans-serif;
    }
  }

  .img-10 {
    aspect-ratio: 0.92;
    object-fit: contain;
    object-position: center;
    width: 12px;
    fill: var(--line, #dee1e6);
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .id-outer {
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--line, #dee1e6);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    background-color: var(--White, #fff);
    align-self: stretch;
    display: flex;
    margin-top: 20px;
    width: 100%;
    gap: 20px;
    padding: 9px 20px 9px 0;
  }

  @media (max-width: 991px) {
    .id-outer {
      margin-right: 5px;
      flex-wrap: wrap;
    }
  }

  .id-inner {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 4px 13px;
  }

  @media (max-width: 991px) {
    .id-inner {
      padding-right: 20px;
    }
  }

  .img-11 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 36px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    max-width: 100%;
  }

  .id-content {
    color: var(--On-Surface-Text-Colour, #202223);
    margin: auto 0;
    font: 600 20px/120% Poppins, sans-serif;
  }

  .id-mail {
    color: var(--On-Surface-Text-Colour, #202223);
    margin: auto 0;
    font: 400 20px/120% Poppins, sans-serif;
  }

  .div-20 {
    color: var(--On-Surface-Text-Colour, #202223);
    margin: auto 0;
    font: 600 20px/120% Poppins, sans-serif;
  }

  .div-21 {
    color: var(--On-Surface-Text-Colour, #202223);
    margin: auto 0;
    font: 400 20px/120% Poppins, sans-serif;
  }

  .div-22 {
    align-content: flex-start;
    flex-wrap: wrap;
    align-self: flex-end;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    gap: 20px;
  }

  @media (max-width: 991px) {
    .div-22 {
      max-width: 100%;
    }
  }

  .span-15 {
    display: flex;
    gap: 8px;
  }

  .div-23 {
    color: var(--Primary-text, #2d2f31);
    align-self: center;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-23 {
      white-space: initial;
    }
  }

  .span-16 {
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: var(--White, #fff);
    display: flex;
    justify-content: space-between;
    gap: 0px;
    padding: 10px 9px;
  }

  .div-24 {
    color: var(--Primary-text, #2d2f31);
    text-align: center;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-24 {
      white-space: initial;
    }
  }

  .img-15 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .div-25 {
    color: var(--Peimary, #2181fa);
    align-self: center;
    flex-grow: 1;
    white-space: nowrap;
    margin: auto 0;
    font: 600 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-25 {
      white-space: initial;
    }
  }

  .div-26 {
    display: flex;
    gap: 6px;
  }

  @media (max-width: 991px) {
    .div-26 {
      max-width: 100%;
      flex-wrap: wrap;
    }
  }

  .span-17 {
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    display: flex;
    gap: 4px;
    padding: 10px 12px;
  }

  .img-16 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
  }

  .div-27 {
    color: var(--colors-black-1-primary-text, #313131);
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-27 {
      white-space: initial;
    }
  }

  .span-18 {
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    display: flex;
    gap: 4px;
    padding: 10px 12px;
  }

  .img-17 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    overflow: hidden;
    max-width: 100%;
    margin: auto 0;
  }

  .div-28 {
    color: var(--colors-black-1-primary-text, #313131);
    align-self: stretch;
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-28 {
      white-space: initial;
    }
  }

  .span-19 {
    color: var(--colors-black-1-primary-text, #313131);
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    aspect-ratio: 1;
    height: 40px;
    padding: 0 18px;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .span-19 {
      white-space: initial;
    }
  }

  .span-20 {
    color: var(--White, #fff);
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--Peimary, #2181fa);
    aspect-ratio: 1;
    height: 40px;
    padding: 0 16px;
    font: 600 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .span-20 {
      white-space: initial;
    }
  }

  .span-21 {
    color: var(--colors-black-1-primary-text, #313131);
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    aspect-ratio: 1;
    height: 40px;
    padding: 0 16px;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .span-21 {
      white-space: initial;
    }
  }

  .span-22 {
    color: var(--colors-black-1-primary-text, #313131);
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    aspect-ratio: 1;
    height: 40px;
    padding: 0 16px;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .span-22 {
      white-space: initial;
    }
  }

  .div-29 {
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    display: flex;
    aspect-ratio: 1;
    flex-direction: column;
    width: 40px;
    height: 40px;
    padding: 0 12px;
  }

  .img-18 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 100%;
    overflow: hidden;
  }

  .span-23 {
    color: var(--colors-black-1-primary-text, #313131);
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    aspect-ratio: 1;
    height: 40px;
    padding: 0 12px;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .span-23 {
      white-space: initial;
    }
  }

  .span-24 {
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--line, #dee1e6);
    background-color: #fff;
    display: flex;
    gap: 4px;
    padding: 10px 12px;
  }

  .div-30 {
    color: var(--colors-black-1-primary-text, #313131);
    flex-grow: 1;
    white-space: nowrap;
    font: 400 14px/143% Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-30 {
      white-space: initial;
    }
  }

  .img-19 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .img-20 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 16px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .select-outer {
    border-top: 1px solid var(--line, #dee1e6);
    background-color: var(--White, #fff);
    display: flex;
    margin-top: 60px;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 30px;
  }

  @media (max-width: 991px) {
    .select-outer {
      max-width: 100%;
      flex-wrap: wrap;
      margin-top: 40px;
      padding: 0 20px;
    }
  }

  .select-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
  }

  .select {
    color: var(--text-2, #a29e9e);
    align-self: center;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
  }

  @media (max-width: 991px) {
    .div-32 {
      white-space: initial;
    }
  }

  .img-21 {
    aspect-ratio: 0.92;
    object-fit: contain;
    object-position: center;
    width: 12px;
    fill: var(--line, #dee1e6);
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .img-22 {
    aspect-ratio: 0.92;
    object-fit: contain;
    object-position: center;
    width: 12px;
    fill: var(--line, #dee1e6);
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
  }

  .open {
    color: var(--text-2, #a29e9e);
    align-self: flex-start;
    margin-top: 7px;
    font: 400 14px Poppins, sans-serif;
  }

  .img-23 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }

  .open-tab {
    color: var(--text-2, #a29e9e);
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
  }

  .img-24 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }

  .img-25 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }

  .advance {
    color: var(--Peimary, #2181fa);
    text-align: right;
    margin: auto 0;
    font: 400 14px Poppins, sans-serif;
  }
}

.flex-box-two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fae0e0;
  min-height: 120px;
  padding: 30px 75px;
  position: absolute;
  top: 97%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  width: calc(100% + 2px);
  font-size: 14px;
  color: #2d2f31;

  .fa-circle-xmark:before {
    color: #fd5c5c;
    font-size: 24px;
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  padding: 20px 30px;
  margin: 0 0 20px;

  @media (max-width: 767px) {
    gap: 8px;
    justify-content: center;
    padding: 20px 15px;

    img,
    svg {
      height: 12px;
      width: 12px;
      object-fit: contain;
    }
  }

  @media (max-width: 576px) {

    img,
    svg {
      width: 8px;
      height: 8px;
      object-fit: contain;
    }
  }

  span {
    color: #2d2f31;
    font-size: 14px;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .form-select {
    width: auto;
    display: inline-flex;
    border: none;
    box-shadow: none;
    color: #2d2f31;
    font-size: 14px;
    padding: 0 34px 0 0;
    height: auto;
    margin: 0 0 0 0;
    margin-top: 0 !important;

    @media (max-width: 767px) {
      font-size: 12px;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.access-denied-page {
  display: flex;
  justify-content: center;
  padding: 90px 10px;

  .access-page-wrapper {
    // width: 35%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  }

  .lock-img {
    text-align: center;
    padding: 64px;

    img {
      width: 235.237px;
      height: 196.807px;
      object-fit: contain;
    }

    h2 {
      color: #2d2f31;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: -1.28px;
      padding: 18px;
    }

    button {
      width: 420px;
      padding: 16px 38px;
      border-radius: 7px;
      background: var(--Main-Color-Main-Color, #2181fa);
      border: none;
      color: white;
    }
  }

  .footer-wrapper {
    height: 122px;
    border-radius: 0px 0px 7px 7px;
    background: #fae0e0;

    .img-inner {
      display: flex;
      align-items: center;
      padding: 40px 48px;
      gap: 12px;

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }

      p {
        color: var(--Primary-text, #2d2f31);
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .access-denied-page {
    .lock-img {
      button {
        width: 225px !important;
      }
    }
  }
}

// .new-header{
//   gap: 10px;
// }
.header {
  // display: flex;
  padding: 12px;
  // justify-content: space-between;

  // align-items: center;
  border-bottom: 1px solid #dee1e6;

  .dropdown-menu-avtar {
    inset: 0px 10px auto auto !important;
    border: 1px solid #dee1e6;
    box-shadow: 0 6px 14px 0 #0000001a;
    border-radius: 8px;
    margin: 20px 0 0 0;

    .menuItems {
      padding: 20px 10px;
      width: 360px;

      .dropdown-heading {
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #dee1e6;
        padding-bottom: 20px;
        margin: 0 10px 14px;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50px;
        }

        strong {
          color: var(--text-icons-primary, #2d2f31);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .heading-wrapper {
        height: 36px;
        border-bottom: 1px solid #dee1e6;

        strong {
          color: var(--Font-Colour, #212121);
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .mainItems {
        margin: 8px 0 0;

        .icons-1 {
          border-radius: 37px;
          background: #fff;
          padding: 6px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg,img {
            height: 24px;
            width: 24px;
            object-fit: contain;
          }
        }

        .items {
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          padding: 6px 10px;

          p {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.56px;
            margin: 0px;
          }

          a,
          span {
            color: var(--Primary-text, #2d2f31);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
          }
        }

        .items:hover {
          border-radius: 5px;
          padding: 6px 10px;
          background: var(--Brand-Hover, #edf4ff);

          p,
          a,
          span {
            // color: var(--Primary-text, #2181fa);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.56px;
          }
        }
      }

      .logout-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 6px 10px;

        p {
          color: #212121;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.56px;
          margin: 0px;
        }

        .icons-1 {
          border-radius: 50%;
          background: #fff;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 24px;
            width: 24px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .section1 {
    background: #3b82f6;
    display: flex;
    height: 34px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 34px;

    @media (max-width: 767px) {
      height: 30px !important;
      width: 30px !important;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .section2 {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

    .btn1-parent {
      .dropdown {}
    }

    .shine-icon {
      .icon {
        padding: 5px;
        border: 1px solid #dee1e6;
        width: 36px;
        display: flex;
        height: 36px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: white;

        img {
          width: 18px;
          height: 20px;
          filter: brightness(0) saturate(100%) invert(18%) sepia(2%) saturate(1053%) hue-rotate(169deg) brightness(93%) contrast(95%);
        }
      }
    }

    .avatar-icon {
      border: none;
      padding: 0;
      width: 36px;
      height: 36px;
      position: relative;
      border-radius: 50%;

      @media (max-width: 767px) {
        height: 30px;
        width: 30px;
      }

      button {
        background: transparent;
        padding: 0;
        border: none;
      }

      img {
        height: 32px;
        width: 32px;

        @media (max-width: 767px) {
          height: 30px !important;
          width: 30px !important;
        }
      }

      button:hover {
        background-color: transparent;
      }

      .online-status {
        height: 7.2px;
        width: 7.2px;
        background-color: #00ab1b;
        border: 1px solid #ffffff;
        border-radius: 50%;
        position: absolute;
        right: 3px;
        bottom: 3px;
      }
    }
  }
}

// profile page

.profile-form {
  margin: 70px 0;

  .Connection {
    .MuiBox-root {
      .MuiFormControl-root {
        width: 100% !important;
      }
    }
  }
}

.User-profile-data {
  margin: 75px 0 30px;

  @media screen and (max-width: 767px) {
    margin: 40px 0 30px;
  }

  .profile-logo-wrapper {
    display: grid;
    grid-template-columns: 100px auto;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      grid-template-columns: 70px auto;
    }

    .img-wrapper {
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;

        @media screen and (max-width: 767px) {
          width: 70px;
          height: 70px;
        }
      }
    }

    .content-wrapper {
      h2 {
        margin: 0 0 17px;
        white-space: nowrap;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;

        @media screen and (max-width: 767px) {
          margin: 0 0 0 5px;
        }
      }

      span {
        color: #a29e9e;
      }
    }
  }

  .custom-button {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .profile-data {
    display: flex;
    grid-column-gap: 60px;
    flex-wrap: wrap;
    grid-row-gap: 30px;
    margin: 0 0;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .list {
      display: grid;
      grid-template-columns: 20px auto;
      gap: 10px;
      align-items: center;
      position: relative;

      &:not(&:last-of-type) {
        &:after {
          content: "";
          display: block;
          width: 1px;
          background: #dee1e6;
          height: 40px;
          position: absolute;
          right: -29px;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (max-width: 767px) {
            display: none !important;
          }
        }
      }

      .img-wrapper {

        img,
        svg {
          width: 20px;
          height: 20px;
          object-fit: contain;

          @media screen and (max-width: 767px) {
            width: 18px;
            height: 18px;
          }
        }
      }

      .text {
        p {
          margin: 0 0 0;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.starIcon {
  border-radius: 4px;
  border: 1px solid var(--line, #dee1e6);
  background: #fff;
  width: 40px;
  height: 40px;
  padding: 5px 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

  img {
    width: 24px;
    height: 24px;

    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
}

.new-profile-form {
  // height: 100vh;
  padding: 0px 40px !important;
}

.new-profile-card {
  padding: 40px 40px 0px 40px !important;
}

.filter-bar {
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 16px;
    height: 16px;
  }

  .form-group {
    display: flex;
    align-items: center;
    gap: 14px;
    border-radius: 4px;
    border: 1px solid #dee1e6;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    padding: 0 10px;
    // height: 40px;

    img {
      width: 20px;
      height: 20px;
    }

    input.form-control {
      color: #a29e9e !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      border: none !important;
      outline: none !important;
      height: auto !important;
      padding: 8px 20px 8px 8px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: #a29e9e;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .select-wrapper {
      select {
        outline: none;
        border: none;
        width: 100%;
        box-shadow: none !important;
      }
    }

    span {
      color: #2d2f31;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.new-page-title {
  display: flex;
  justify-content: space-between;
  /* width: 100px; */
  align-items: center;
  border-bottom: 1px solid #dee1e6;
}

//humayon's css//

.settings-page-wrapper {
  // display: flex;

  .page-content {
    padding: 40px 40px;
    // height: calc(100vh - 128px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .page-title {
      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        padding-bottom: 24px;
        color: #2d2f31;
        // border-bottom: 1px solid #dee1e6;
        margin-bottom: 0;
      }
    }

    .information-cards-wrapper {
      // margin-top: 40px;
      margin-top: 20px;
      &.information-top-space{
        margin-top: 40px;
      }

      .single-information-card {
        // margin-bottom: 20px;
        .field-title{
          h4{
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            margin: 0;
          }
        }

        .information-content {
          border: 1px solid #dee1e6;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 10px;
          max-height: 160px;
          .error-handle{
            max-width: 200px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          h5{
            text-wrap: nowrap;
            margin-top: 10px;
          }

          .single-information {
            display: flex;
            justify-content: space-between;

            .title.profile-title {
              color: #9e9fa2;

              h5 {
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                // color: #2d2f31;
                color: #9E9FA2;
                margin: 0;
              }
            }

            .profile-image {
              width: 100px;
              height: 100px;
              position: relative;

              .img-wrapper {

                >img,
                svg {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }

              .edit-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: white;
                border: 0.72px solid #dee1e6;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 26px;
                height: 26px;

                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }

        .info-title {
          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #2d2f31;
            margin: 0;
          }
        }

        .info-section-wrapper {
          border: 1px solid #dee1e6;
          padding: 20px;
          border-radius: 6px;

          .main-info {
            display: grid;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            grid-template-columns: 40% 53%;

            label {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #2d2f31;
            }

            
            // .main-calender {
            //   position: relative;
            //   display: flex;
            //   justify-content: flex-end;
            //   .date-picker-container {
            //     .input-wrapper {
            //       position: relative;
            //       max-width: 320px;
            //       min-width: 320px;
            //       width: 100%;
            //       input {
            //         width: 100%;
            //         max-width: 320px;
            //         min-width: 320px;
            //         font-weight: 400;
            //         font-size: 14px;
            //         line-height: 18px;
            //         padding: 10px 10px 10px 14px;
            //         border-radius: 6px;
            //         border: 1px solid #cccccc;
            //         background-color: #ffffff;
            //         outline: none;
            //       }
            //       .calendar-icon {
            //         position: absolute;
            //         right: 13px;
            //         top: 11.5px;
            //       }
            //     }
            //   }
            //   .react-datepicker-popper {
            //     .react-datepicker {
            //       max-width: 320px;
            //       border: 1px solid #dee1e6;
            //       box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.1019607843);
            //       border-radius: 10px;
            //     }
            //   }
            // }
          }

          .single-info {
            display: grid;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            grid-template-columns: 40% 53%;
            .main-calender{
              display: flex;
              justify-content: end;
              .react-calendar__navigation{
                border-bottom: 1px solid #dee1e6;
                button {
                  color: #9E9FA2;
                }
              }
              abbr[title] {
                text-decoration: none;
              }
              .react-date-picker__calendar .react-calendar{
                max-width: 320px;
                border: 1px solid #dee1e6;
                box-shadow: 0px 9px 19px 0px #0000001A;
                border-radius: 10px;
                @media screen and (max-width:767px) {
                  min-width: 186px;
                  max-width: 186px;
                }
              }
              .react-calendar__tile--active {
                // background-color: $blue;
                width: 28px;
                min-width: 28px;
                max-width: 28px;
                height: 28px;
                padding: 0;
                border-radius: 50%;
                margin: 10px;
              }
              .react-calendar__tile--now {
                background-color: #EDF4FF;
                width: 28px;
                min-width: 28px;
                max-width: 28px;
                height: 28px;
                padding: 0;
                border-radius: 50%;
                margin: 10px;
                color: #2D2F31;
              }
              .react-date-picker__wrapper {
                width: 100%;
                max-width: 320px;
                min-width: 320px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                padding: 10px 10px 10px 14px;
                border-radius: 6px;
                border: 1px solid #cccccc;
                background-color: #ffffff;
                outline: none;
                @media screen and (max-width:767px) {
                  min-width: 186px;
                  max-width: 186px;
                }
        
                
                button.react-date-picker__clear-button.react-date-picker__button {
                  display: none;
                }
              }
              
              input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
               .react-date-picker__inputGroup__day, 
               .react-date-picker__inputGroup__year
               {
                color: #2d2f31;
                padding: 0 !important;
                border: none !important;
              }
            }

            .single-info-text-img-parent {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon-parent {
                padding: 6px;
                box-shadow: 0px 1.54px 4.63px 0px #00000040;
                border-radius: 50%;

                img {
                  object-fit: contain;
                  height: 24px;
                  width: 24px;
                }
              }
            }

            .custom-form {
              input {
                font-size: 15px;
                width: 100%;
                font-weight: 400;
                line-height: 18px;
                color: #2d2f31;
                border: 1px solid #cccccc;
                border-radius: 12px !important;
                max-width: 0px !important;
                padding: 10px 20px;
              }
            }

            .single-info-comp1 {
              display: flex;
              flex-direction: column;

              .text1 {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #2d2f31;
              }

              .text2 {
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #9e9fa2;
              }
            }

            textarea {
              border: 1px solid #cccccc;
              border-radius: 6px;
              max-width: 320px;
              width: 100%;
              color: #2d2f31;
              padding: 12px 12px 10px 14px;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: #2d2f31;
              height: 90px;
              resize: none;

              &::placeholder {
                color: rgb(196, 193, 193);
              }

              &::-webkit-scrollbar {
                display: none;
              }
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            .region {
              border: 1px solid #cccccc;
              border-radius: 6px;
              max-width: 320px;
              width: 100%;
              padding: 12px 12px 10px 14px;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: #2d2f31;
              display: flex;
              gap: 10px;
              align-items: center;
              background-color: #fcfcfc;
            }

            p,label {
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #2d2f31;
            }

            .time-picker {
              position: relative;
              max-width: 320px;
              width: 100%;
              padding: 8px;
              border: 1px solid #ccc;
              border-radius: 6px;
              display: flex;
              align-items: center;
            }

            .time-icon {
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);

              img {
                width: 18px; // Adjust size if necessary
                height: 18px;
              }
            }

            .time-select-container {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: end;
            }

            .time-input {
              width: 100%;
              padding: 0 0 0 40px; // Space for icons on both sides
              border: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              font-size: 16px;
              color: #2d2f31;
              background-color: #fff;
              background-image: url("./images/dropdown-icon.svg");
              background-repeat: no-repeat;
              background-position: right 4px center; // Custom arrow position
              cursor: pointer;
            }

            .time-input:focus {
              outline: none;
            }

            .time-input option {
              color: #333;
            }

            .flags-field {
              border-radius: 6px;
              max-width: 320px;
              width: 100%;
              color: #2d2f31;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;

              &::after {
                content: "";
                background-image: url("./images/dropdown-icon.svg");
                background-size: contain;
                background-repeat: no-repeat;
                width: 12px;
                height: 12px;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }

              .css-1okebmr-indicatorSeparator,
              // Targets the indicator separator
              .css-tlfecz-indicatorContainer {
                // Targets the dropdown arrow
                display: none;
              }

              .css-1wy0on6 {
                display: none;

                &:focus {
                  outline: none;
                }
              }

              .css-13cymwt-control {
                padding: 4px 4px 4px 4px;

                .css-1nmdiq5-menu {
                  .css-1wy0on6 {
                    .css-1u9des2-indicatorSeparator {
                      display: none;
                    }
                  }
                }

                .css-1wy0on6 {
                  .css-1u9des2-indicatorSeparator {
                    display: none;
                  }
                }
              }
            }

            .single-info-input-wrapper {
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
              align-items: flex-end;
              position: relative;

              // text-align: center;
              .error-msg {
                // width: 100%;
                // text-align: center;
                // position: absolute;
                // top: 98%;
                // right: 12%;
                display: flex;
                justify-content: left;
                align-items: left;
                max-width: 320px;
                width: 100%;
                position: relative;
                margin-top: 5px;
                margin-bottom: 0px;

                &.id-error {
                  h5 {
                    margin-left: 0px;
                  }
                }

                h5 {
                  // margin-top: 10px;
                  // margin-bottom: 0px;
                  // margin-left: 70px;
                  // margin: 10px 0px 0px 70px;
                }
              }

              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              input {
                border: 1px solid #cccccc;
                border-radius: 6px;
                max-width: 320px;
                width: 100%;
                color: #2d2f31;
                padding: 12px 12px 10px 14px;
                font-size: 14px;
                // font-weight: 500;
                font-weight: 400;
                line-height: 18px;
                color: #2d2f31;
                // color: #000;

                &.gray-bg {
                  background-color: #fcfcfc;
                }

                &:focus {
                  outline: none;
                }

                &::placeholder {
                  // color: #2d2f31;
                  color: rgb(196, 193, 193);
                }
              }
            }
          }

          .head-sec {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #dee1e6;

            span {
              color: #202223;
              line-height: 18px;
              font-size: 20px;
              font-weight: 600;
            }

            .input-field {
              text-align: right;

              button {
                border: 1px solid #2181fa;
                padding: 8px 16px;
                border-radius: 5px;
                color: #2181fa;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

.calendar-popup {
  position: absolute;
  inset: auto auto 100% 0px;

  .react-calendar {
    max-width: 320px;
    border: 1px solid #dee1e6;
    box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.1019607843);
    border-radius: 10px;

    .react-calendar__navigation {
      border-bottom: 1px solid #dee1e6;

      .react-calendar__navigation__arrow {
        color: #9e9fa2;
        font-size: 24px;
      }

      .react-calendar__navigation__label {
        span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          font-family: "Poppins";
          color: #2d2f31 !important;
        }
      }
    }

    abbr[title] {
      text-decoration: none;
    }

    .react-date-picker__calendar .react-calendar {
      max-width: 320px;
      border: 1px solid #dee1e6;
      box-shadow: 0px 9px 19px 0px #0000001a;
      border-radius: 10px;

      @media screen and (max-width: 767px) {
        min-width: 186px;
        max-width: 186px;
      }
    }

    .react-calendar__tile--active {
      background-color: #2181fa;
      width: 28px;
      min-width: 28px;
      max-width: 28px;
      height: 28px;
      padding: 0;
      border-radius: 50%;
      margin: 10px;
    }

    .react-calendar__tile--now {
      background-color: #edf4ff;
      width: 28px;
      min-width: 28px;
      max-width: 28px;
      height: 28px;
      padding: 0;
      border-radius: 50%;
      margin: 10px;
      color: #2d2f31;
    }

    .react-date-picker__wrapper {
      width: 100%;
      max-width: 320px;
      min-width: 320px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 10px 10px 14px;
      border-radius: 6px;
      border: 1px solid #cccccc;
      background-color: #ffffff;
      outline: none;

      @media screen and (max-width: 767px) {
        min-width: 186px;
        max-width: 186px;
      }

      button.react-date-picker__clear-button.react-date-picker__button {
        display: none;
      }
    }

    .react-calendar__tile {
      abbr {
        font-size: 12px;
      }
    }

    input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month,
    .react-date-picker__inputGroup__day,
    .react-date-picker__inputGroup__year {
      color: #2d2f31;
      padding: 0 !important;
      border: none !important;
    }
  }
}

.single-info-parent {
  padding: 20px;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 6px;
  max-width: 320px;
  width: 100%;
  color: #2d2f31;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;

  .custom-dropdown {
    width: 96%;
    // padding-right: 40px;
    border: none;
    background-color: transparent;
    padding: 12px 12px 10px 14px;
    color:#2D2F31;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .custom-dropdown:focus {
    border-color: transparent;
    outline: none;
  }

  .dropdown-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    pointer-events: none;
  }

  .dropdown-menu {
    max-width: 202px;
    width: 100%;
    left: 0;
    top: 100%;

    .dropdown-item {
      padding: 11px 10px;
    }
  }
}

.header-bottom-container {
  display: flex;
  align-items: center;
  padding: 14px 40px;
  gap: 20px;
  border-bottom: 1px solid #dee1e6;

  .header-bottom-btn {
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
    background: transparent;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2d2f31;
      padding: 17px 0px;
      transition: color 0.3s ease, border-bottom 0.3s ease;
      text-transform: capitalize;
    }

    &.active span {
      color: #2181fa;
      border-bottom: 1px solid #2181fa;
      font-weight: 600;
    }
  }

  .bar {
    color: gray;
    margin: 0 10px;
  }
}

.custom-modal-password-change {
  .modal-dialog {
    max-width: 700px;

    .modal-header {
      padding: 15.5px 20px;

      .modal-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        color: #2d2f31;
      }
    }

    .modal-body {
      padding: 40px 20px;

      .form {
        .form-group {
          margin-bottom: 10px;
          position: relative;

          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #2d2f31;
          }

          .input-group {
            padding: 12px 20px;
            border: 1px solid #dee1e6;
            border-radius: 7px;
          }

          img {
            position: absolute;
            right: 15px;
            top: 37px;
            height: 24px;
            width: 24px;
            object-fit: contain;
          }
        }
      }
    }

    .modal-footer {
      padding: 8.5px 12px;

      .cancel-button {
        border: 1px solid #006bf1;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 2px 12px;
        color: #006bf1;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .update-button {
        border: none;
        border-radius: 4px;
        background-color: #006bf1;
        padding: 3px 12px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}

.no-content-data {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .shine-icon-wrapper {
    padding: 15px;
    box-shadow: 0px 1px 3px 0px #00000040;
    border-radius: 50%;

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
  }

  strong {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: #2d2f31;
  }
}

.dm-parent {
  position: relative;
  padding: 40px;
  width: 100%;
  min-height: 100vh;

  .sender-message,
  .receiver-message {
    margin-bottom: 59px;

    .wrapper {
      display: flex;
      align-items: center;
      gap: 24.14px;

      img {
        height: 40px;
        width: 40px;
        object-fit: contain;
        border-radius: 50%;
      }

      p {
        margin: 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26.4px;
        color: #424856;
      }
    }
  }

  .input-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 40px;

    .input-container {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      .input-field {
        width: 100%;
        padding: 18px 65px;
        border: 1px solid #dee1e6;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26.4px;
        color: #a29e9e;
      }

      .icon-start,
      .icon-end {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .icon-start {
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        border: 1px solid #dee1e6;
        border-radius: 4px;
        padding: 7px;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }
      }

      .icon-end {
        right: 10px;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }
      }
    }
  }
}

//------------------------footer-organization-page--/////////

.footer-organization-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dee1e6;
  padding: 20.5px 20px;
  position: fixed !important;
  bottom: 0;
  width: 100%;
  left: 0;

  @media only screen and (max-height: 900px) {
    // position: relative;
    background: #ffffff;
  }

  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #404040;
  }

  .term-oraganization {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .main-status {
    .circle-status {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 14px;
        height: 14px;
        object-fit: contain;
      }
    }
  }
}


.avaterModal{
  .modal-dialog{
    max-width: 600px;

    .modal-footer{
      button{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 12px 18px;
        border: 1px solid #2181FA;
        background-color: #2181FA;
        color: #FFFFFF;
        border-radius: 5px;
        height: 36px;
        &.cancel-btn{
          background-color: transparent;
          color: #000;
        }
      }
    }
    

  }

}

.setting-main-wrapper{
  height: calc(100vh - 120px);
  overflow: auto;
}


.intercom-launcher, .intercom-launcher-frame{
  display: none !important;
}
.intercom-launcher.intercom-launcher-active{
  display: none !important;
}
.intercom-namespace .intercom-with-namespace-r0cx9n{
  display: none !important;
}